if (document.getElementById("calculate-savings-btn")) {
    let savingsFormButton = document.getElementById("calculate-savings-btn");

    savingsFormButton.onclick = function () {
        const projectValue = parseFloat(
            document.getElementById("project-value").value.replace(/,/g, '').replace(/\$/g, '')
        );

        if (projectValue <= 0) {
            return false;
        }

        const projectLength = document.getElementById("project-length").value;

        if (projectLength <= 0) {
            return false;
        }

        const visitorEmail = document.getElementById("visitor-email").value;

        if (visitorEmail === '') {
            return false;
        } else if (/\S+@\S+\.\S+/.test(visitorEmail)) {
            //pass
        } else {
            return false;
        }

        const softwareSavings = (projectValue * 0.001) + (projectValue * 0.0003) +
            (projectLength * 1000) - (((projectLength / 12) * 5760) + 3000);

        const caSavings = projectValue * 0.00045;

        const totalSavings = softwareSavings + caSavings;

        const toDecimalMark = num => num.toLocaleString('en-US');

        document.getElementById("software-savings").innerText = "$" + toDecimalMark(softwareSavings);
        document.getElementById("ca-savings").innerText = "$" + toDecimalMark(caSavings);
        document.getElementById("total-savings").innerText = "$" + toDecimalMark(totalSavings);

        const request = new Request(
            "https://hooks.slack.com/services/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",
            {
                method: "POST",
                mode: "no-cors",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    "text": "Savings Calculator Utilized | Email: " + visitorEmail +
                        " | Project Value: " + "$" + toDecimalMark(projectValue)
                })
            }
        );

        fetch(request).then();
    };
}
