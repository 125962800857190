if (document.getElementById("contact-form-button")) {
    let contactFormButton = document.getElementById("contact-form-button");

    contactFormButton.onclick = function () {
        const contactFirstName = document.getElementById("contact-form-fname");

        if (contactFirstName.value === '') {
            return false;
        }

        const contactLastName = document.getElementById("contact-form-lname");

        if (contactLastName.value === '') {
            return false;
        }

        const contactEmail = document.getElementById("contact-form-email");

        if (contactEmail.value === '') {
            return false;
        } else if (/\S+@\S+\.\S+/.test(contactEmail.value)) {
            //pass
        } else {
            return false;
        }

        const contactCompany = document.getElementById("contact-form-company");

        if (contactCompany.value === '') {
            return false;
        }

        const contactSubject = document.getElementById("contact-form-subject");

        if (contactSubject.value === '') {
            return false;
        }

        const contactMessage = document.getElementById("contact-form-message");

        if (contactMessage.value === '') {
            return false;
        }

        const request = new Request(
            "https://api.jet.build/jet-crm/contact-form",
            {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Token 4567c6e8ba86e8a11afe35050416ce887e4c3fd3",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "email": contactEmail.value,
                    "fname": contactFirstName.value,
                    "lname": contactLastName.value,
                    "company": contactCompany.value,
                    "subject": contactSubject.value,
                    "message": contactMessage.value
                })
            }
        );

        fetch(request).then(response => {
            return response;
        }).then(data => {
            contactFormButton.setAttribute("disabled", "");
            contactFormButton.innerHTML = "Message Sent! Thank you";
        });
    };
}
